<template>
  <div class="channel-list-wrapper">
    <b-card no-body class="mb-0">
      <template #header>
        <div class="mt-2 w-auto ml-0 mr-auto">
          <b-form-select
            id="table-select-mode-select"
            v-model="selectMode"
            :options="modes"
          ></b-form-select>
          <div class="mt-1">
            <b-button
              v-if="selectMode !== 'single'"
              size="sm"
              class="bg-primary mr-1"
              @click="selectAllRows"
            >
              Select all
            </b-button>
            <b-button size="sm" class="bg-primary" @click="clearSelected">
              Clear selected
            </b-button>
            <b-button
              v-if="selected.length !== 0"
              :disabled="loading"
              size="sm"
              variant="primary"
              class="ml-1"
              @click="deleteSelected"
            >
              Delete selected
            </b-button>
          </div>
        </div>
        <div id="without-program" class="mt-2 w-auto ml-0 mr-auto">
          <b-form-checkbox
            :checked="!!filter.without_program"
            @input="setFilter({ without_program: $event })"
          >
            Without program
          </b-form-checkbox>
        </div>
        <div id="not-updated" class="mt-2 w-auto mx-auto">
          <b-form-checkbox
            :checked="!!filter.not_updated"
            @input="setFilter({ not_updated: $event })"
          >
            Not updated
          </b-form-checkbox>
        </div>
        <b-tooltip target="without-program" variant="info" triggers="hover">
          Lists channels that do not have program
        </b-tooltip>
        <b-tooltip target="not-updated" variant="info" triggers="hover">
          Lists channels that was last updated more then 24h ago
        </b-tooltip>
        <search-input @search="setFilter({ search: $event })"></search-input>
      </template>
      <table-spinner :loading="loading || stateLoading"></table-spinner>
      <b-table
        selectable
        :select-mode="selectMode"
        @row-selected="onRowSelected"
        ref="channelTable"
        v-if="!loading && !stateLoading"
        id="channel-list"
        class="position-relative"
        :items="data"
        responsive
        primary-key="id"
        :fields="fields"
        show-empty
        empty-text="No matching records found"
      >
        <template #head()="fields">
          <table-header-labels
            :fields="fields"
            :filter="filter"
            @onSort="changePage($event)"
          ></table-header-labels>
        </template>
        <template #cell(selected)="{ rowSelected }">
          <template>
            <b-form-checkbox disabled :checked="rowSelected"></b-form-checkbox>
          </template>
        </template>
        <template #cell(last_imported_at)="data">
          <span>
            {{ $dateHelpers.formatDate(data.item.last_imported_at) }} -
            {{ $dateHelpers.formatTime(data.item.last_imported_at) }}
          </span>
        </template>
        <template #cell(old)="data">
          <span>
            <feather-icon
              class="text-warning"
              icon="AlertTriangleIcon"
              size="20"
              :id="'tooltip-target' + data.item.id"
              v-if="showAlert(data.item.last_imported_at)"
            />
            <div v-else>-</div>
            <b-tooltip
              :target="'tooltip-target' + data.item.id"
              triggers="hover"
              variant="warning"
            >
              Channel info was updated last time more than 24h ago!
            </b-tooltip>
          </span>
        </template>
        <template #cell(is_notifiable)="data">
          <b-form-checkbox
            v-model="data.item.is_notifiable"
            @change="onIsNotifiableToggle(data.item, $event)"
          ></b-form-checkbox>
        </template>
        <template #cell(delete)="data">
          <button
            :id="'delete-btn' + data.item.id"
            class="btn text-danger p-0"
            @click="deleteChannel(data.item)"
          >
            <feather-icon icon="TrashIcon" size="20" />
          </button>
          <b-tooltip
            :target="'delete-btn' + data.item.id"
            triggers="hover"
            variant="danger"
          >
            Delete channel
          </b-tooltip>
        </template>
      </b-table>
      <template #footer>
        <pagination
          :meta-data="meta"
          @change="setFilter({ page: $event })"
          @change-page="changePage({ page: $event })"
          @change-per-page="changePerPage({ per_page: $event })"
        >
        </pagination>
      </template>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BFormCheckbox,
  BTooltip,
  BFormSelect,
  BButton,
} from "bootstrap-vue";
import { mapActions } from "vuex";
import Pagination from "@/layouts/components/XmlTvPagination.vue";
import debounce from "lodash.debounce";
import { TWENTYFOUR_HOURS_IN_MILLISECONDS } from "@/services/utils/util";
import TableSpinner from "@/layouts/components/TableSpinner.vue";
import SearchInput from "@/layouts/components/form/SearchInput.vue";
import TableHeaderLabels from "@/layouts/components/TableHeaderLabels.vue";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import { deleteChannel } from "@/services/api/admin";

export default {
  components: {
    BCard,
    BTable,
    BFormSelect,
    BButton,
    Pagination,
    BFormCheckbox,
    TableSpinner,
    BTooltip,
    SearchInput,
    TableHeaderLabels,
    FeatherIcon,
  },
  data() {
    return {
      modes: ["multi", "single", "range"],
      fields: [
        { key: "selected", label: "Selected" },
        { key: "name", label: "Name" },
        { key: "tvg_id", label: "Tvg id" },
        { key: "program_count", label: "Programs" },
        { key: "last_imported_at", label: "Last Program Import" },
        { key: "old", label: "not up-to-date", sortable: false },
        { key: "country_name", label: "Country name", sortable: false },
        { key: "is_notifiable", label: "Notifiable", sortable: false },
        { key: "delete", label: "", sortable: false },
      ],
      selectMode: "multi",
      selected: [],
      loading: false,
    };
  },
  computed: {
    data() {
      return this.$store.getters["adminChannelList/getChannelListData"];
    },
    filter() {
      return {
        ...this.$route.query,
        page: this.$route.query?.page ?? 1,
        per_page: this.$route.query?.per_page ?? 10,
        sort_by: this.$route.query?.sort_by ?? "name",
        order_by: this.$route.query?.order_by ?? "asc",
      };
    },
    meta() {
      return this.$store.getters["adminChannelList/getChannelListMeta"];
    },
    stateLoading() {
      return this.$store.getters["adminChannelList/isLoading"];
    },
  },
  mounted() {
    this.getChannelList({ ...this.filter }).catch(console.error);
  },
  methods: {
    ...mapActions({
      getChannelList: "adminChannelList/getChannelList",
      updateChannel: "adminChannelList/updateChannel",
      massDeleteChannels: "adminChannelList/massDeleteChannels",
    }),
    setFilter(filter = {}) {
      this.filter.page = 1;
      this.$router.replace({ query: { ...this.filter, ...filter } });

      if (filter.search !== undefined) {
        if (filter.search?.length === 0) {
          this.$router.replace({
            query: { ...this.filter, search: undefined },
          });
        }
      }

      this.onRefreshPage();
    },
    onRefreshPage: debounce(async function () {
      this.loading = true;
      try {
        await this.getChannelList(this.filter);
      } finally {
        this.loading = false;
      }
    }, 300),

    changePage(filter = {}) {
      this.$router.replace({ query: { ...this.filter, ...filter } });
      this.onRefreshPage();
    },
    changePerPage(filter = {}) {
      this.filter.page = 1;
      this.$router.replace({ query: { ...this.filter, ...filter } });
      this.onRefreshPage();
    },
    showAlert(updated) {
      const now = Date.now();
      const updated_at = Date.parse(updated);
      const timeDiff = now - updated_at;
      if (timeDiff > TWENTYFOUR_HOURS_IN_MILLISECONDS) {
        return true;
      } else {
        return false;
      }
    },
    onIsNotifiableToggle(channel, checked) {
      this.updateChannel({
        id: channel.id,
        is_notifiable: checked,
      });
    },
    async deleteChannel(channel) {
      this.$swal({
        title: `Deleting ${channel.name}.`,
        text: "Are you sure you want to delete this channel?",
        type: "warning",
        icon: "warning",
        showCancelButton: true,
        showCloseButton: true,
        focusConfirm: true,
        confirmButtonColor: "#4BB543",
        confirmButtonText: "Yes, delete it.",
      }).then(async (result) => {
        if (result.value) {
          await deleteChannel(channel.id);
        }
        await this.onRefreshPage();
      });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.channelTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.channelTable.clearSelected();
    },
    async deleteSelected() {
      var selectedIds = [];
      this.selected.forEach((element) => {
        selectedIds.push(element.id);
      });
      this.loading = true;
      try {
        await this.massDeleteChannels(selectedIds);
        this.onRefreshPage();
      } finally {
        this.selected = [];
      }
    },
  },
};
</script>

<style scoped>
.table-header-label {
  margin-top: 5px;
  white-space: nowrap;
}
</style>
